.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

.Text-anim {
    animation: Text-move 1s ease;
}

.App-header {
  background-image: radial-gradient(#827b7b, #000000);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #3e484b;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes Text-move {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0%);
  }
}

@font-face {
  font-family: 'UnicaOne';
  src: local('UnicaOne'), url(./assets/UnicaOne-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Consolas';
  src: local('Consolas'), url(./assets/Consolas.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}